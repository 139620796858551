<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-row>
        <v-col
          cols="12"
          md="4"
        ></v-col>
        <v-text-field
            v-model="numberB"
            
            
            label="Номер брони"
            required
          ></v-text-field>
          <v-col
          cols="12"
          md="4"
          >
          <v-btn @click="click()">
  Поиск
</v-btn></v-col>
        </v-row>
        <v-sheet class="mx-auto" width="300">
    <v-form disabled>
      <v-text-field
        v-model="firstname"
        label="Клиент"
      ></v-text-field>
      <v-text-field
        v-model="carName"
        label="Автомобиль"
      ></v-text-field>
    </v-form>
  </v-sheet>
        <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="kabel" label="Кабель ЗУ"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="rezina" label="Зимняя резина"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="shina" label="Шины целые"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="kuzov" label="Кузов чистый?"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="salon" label="Салон чистый?"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="bagazh" label="Багажник чистый?"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="video" label="Видеорегистратор"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="kreslo" label="Детское кресло"></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox v-model="shetka" label="Щетка"></v-checkbox>
        </v-col>


       
      </v-row>


      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="probeg"
          
            label="Пробег"
            
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="benzin"
            
            label="Бензина на КМ"
            
          ></v-text-field>
        </v-col>
        </v-row>
        <v-btn>
  Скачать
</v-btn>
    </v-container>
  </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: 'HelloWorld',

  data: () => ({
    kabel: false,
    rezina: false,
    shina: false,
    kuzov: false,
    salon: false,
    bagazh: false,
    benzin: 0,
    probeg: 0,
    video: false,
    kreslo: false,
    shetka: false,
    token: "",
    numberB: '',
    car: [],
    firstname: '',
    carName: ''
  }),
  methods: {
    click(){
      let self = this;
      let tokens;
      axios
      .get('https://rentprog.net/api/v1/public/get_token?company_token=3xvybzsxaj49vy2wx8xgg9xu')
      .then(response => {
             
        this.token = response.data.token
        console.log(this.token)


         axios
                    .get('https://rentprog.net/api/v1/public/search_bookings?query=' + this.numberB, {
                          headers: {
                          "Authorization" : `${this.token}`
                        }
                        })
                        .then(r => {
                          this.car.push(r.data[0])
                          this.carName = r.data[0].car_name
                          this.firstname = r.data[0].first_name + " " + r.data[0].middle_name + " " + r.data[0].last_name
                        })
                        .catch(error => {
                        console.log(error)
                        this.carName = "Ничего не найдено"
                        this.firstname = "Ничего не найдено"
                      })
      })
      



      
    }
  }
}
</script>
